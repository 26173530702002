import type {
  ToastConfig,
  ToastContainerElement
} from '@pocketoutdoormedia/toast/dist/src/types';
import { useShowEmailVerificationWarning } from 'hooks/useShowEmailVerificationWarning';

export const showToast = async (config: ToastConfig) => {
  const { showToast: show } = await import('@pocketoutdoormedia/toast');

  show(config);
};

export const ToastContainer = () => {
  useShowEmailVerificationWarning();

  return (
    <div className="relative">
      <out-toast-container position="bottom" />
    </div>
  );
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'out-toast-container': ToastContainerElement;
    }
  }
}
