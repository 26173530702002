import { gql } from '@apollo/client';

export const GET_LEARN_HOMEPAGE = gql`
  query GetHomepage {
    props: learnHomepage {
      courseBlock {
        courses {
          overview {
            description
            title
          }
          excerpt
          heroImage
          id
          name
          instructorsLabel
          title
          instructors {
            name
          }
        }
        subhead
        title
      }
      promotionalSection {
        video
        title
        subhead
        mediaOptions
        image
        header
        content
        buttonText
        buttonLink
      }
      footerCta {
        buttonLink
        buttonText
        subhead
        title
      }
      hero {
        buttonLink
        buttonText
        subhead
        title
        video
        disclaimer
      }
      id
      seo {
        description
        modifiedTime
        ogDescription
        ogImage
        ogImageHeight
        ogImageWidth
        ogTitle
        publishedTime
        title
      }
      title
      image
    }
  }
`;
