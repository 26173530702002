import React, { useEffect } from 'react';

import { TrackingTriggerProps, Trigger, IContext } from './types';
import { TrackingContext } from './context/tracking-context';

/**
 * A React component to declaratively trigger an event. The event is
 * triggered when this component is mounted into the DOM.
 */
const TrackingTrigger: React.FC<TrackingTriggerProps> = ({
  event,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTrigger = () => {},
  options = {},
  payload,
  children
}) => {
  // eslint-disable-next-line no-underscore-dangle
  let _trigger: Trigger | undefined = () => null;
  useEffect(() => {
    setTimeout(() => {
      const triggerContext = _trigger && _trigger(event, payload, options);
      if (onTrigger) {
        onTrigger(triggerContext);
      }
    }, 500);
  }, [onTrigger, options, payload, event]);

  const handleContext = ({ trigger }: IContext) => {
    // Save a reference to the trigger method for use in componentDidMount.
    _trigger = trigger;
    return children;
  };

  return <TrackingContext.Consumer>{handleContext}</TrackingContext.Consumer>;
};

export { TrackingTrigger };
