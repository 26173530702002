import { gql } from '@apollo/client';

export const GET_LEARN_PAYWALL = gql`
  query getPaywall {
    paywall: learnMarketingModal {
      headline
      benefitsList
      detailsLink
      detailsText
      discountCode
      discountPrice
      price
      priceDuration
    }
  }
`;

export const GET_LEARN_CTA = gql`
  query getCta {
    cta: learnCta {
      buttonText
      description
      headline
      priceDisclaimer
      priceDiscount
    }
  }
`;
