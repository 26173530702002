import { useQuery } from 'react-query';

import {
  ExtendedRawUserProfile,
  PreferencesFromFeedSettings,
  RawUserPreferences,
  UserPreference
} from './types';

const API_KEY = process.env.NEXT_PUBLIC_LEARN_RIVT_API_KEY;
const RIVT_HOST = process.env.NEXT_PUBLIC_RIVT_HOST;

export const getUserProfile = async (
  rivtToken: string
): Promise<ExtendedRawUserProfile> => {
  const rivtURL = new URL('/user/short-profile', RIVT_HOST);
  const response = await fetch(rivtURL, {
    headers: {
      'Api-Key': API_KEY,
      Authorization: `Bearer ${rivtToken}`
    }
  });
  const body = await response.json();
  return body as ExtendedRawUserProfile;
};

export const USER_PROFILE_QUERY_KEY = 'userProfile';

export const useUserProfile = (rivtToken: string) =>
  useQuery({
    enabled: !!rivtToken,
    queryFn: () => getUserProfile(rivtToken),
    queryKey: [USER_PROFILE_QUERY_KEY, rivtToken]
  });

const getUserPreferences = async (
  rivtToken: string
): Promise<RawUserPreferences> => {
  const rivtURL = new URL('/user/settings/feed', RIVT_HOST);
  const response = await fetch(rivtURL, {
    headers: {
      'Api-Key': API_KEY,
      Authorization: `Bearer ${rivtToken}`
    }
  });
  const body = await response.json();
  return body as RawUserPreferences;
};

const getPreferencesFromFeedSettings = (
  feedSettings: RawUserPreferences
): PreferencesFromFeedSettings =>
  [...feedSettings.activities, ...feedSettings.topics].reduce(
    (acc, item: UserPreference) => {
      if (item.selected) {
        acc.preferences.push(item.name);
      }
      item.children?.forEach((child: UserPreference) => {
        if (child.selected) {
          acc.sub_preferences.push(`${item.name}.${child.name}`);
        }
      });
      return acc;
    },
    {
      preferences: [],
      sub_preferences: []
    }
  );

export const getCompleteAuthData = async (
  rivtToken: string
): Promise<ExtendedRawUserProfile> => {
  const userProfile = await getUserProfile(rivtToken);
  const userPreferences = await getUserPreferences(rivtToken);
  // eslint-disable-next-line camelcase
  const { preferences, sub_preferences } =
    getPreferencesFromFeedSettings(userPreferences);

  // eslint-disable-next-line camelcase
  return { preferences, sub_preferences, ...userProfile };
};
