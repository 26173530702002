import { gql } from '@apollo/client';

export const GET_SITEMAP = gql`
  query Sitemap {
    learnCategories {
      name
      courses {
        name
        tableOfContents {
          lessons {
            name
          }
        }
      }
      standaloneLessons {
        name
      }
    }
  }
`;
