import { useAuthService } from '@pocketoutdoormedia/auth-state-service';
import { useEffect } from 'react';
import { useAnalyticsContext } from 'components/04-templates/AnalyticsProvider';
import { getCookie } from 'cookies-next';
import { TRIAL_GROUP_COOKIE_NAME } from 'lib/constants';

import { getCompleteAuthData } from './helpers';

/**
 * TODO We should try to use pid cookie here instead!!!
 * Identifies user on auth-state change
 * Based on:
 * - https://github.com/pocketoutdoormedia/outside-auth-header/blob/main/components/HeaderController/HeaderController.tsx#L164C1-L164C1
 * - https://github.com/pocketoutdoormedia/outside-auth-header/blob/main/components/AuthProvider/AuthProvider.tsx#L102
 */
export const IdentifyAnalyticsService = () => {
  const authService = useAuthService();
  const { identify } = useAnalyticsContext();
  const trialGroup = getCookie(TRIAL_GROUP_COOKIE_NAME);

  useEffect(() => {
    if (authService.userState.isAuthenticated) {
      const { rivtToken } = authService.userState;
      getCompleteAuthData(rivtToken)
        // eslint-disable-next-line no-console
        .then((userProfile) => {
          identify({
            eventId: userProfile.uuid,
            props: {
              firstName: userProfile.first_name,
              lastName: userProfile.last_name,
              email: userProfile.email,
              gender: userProfile.gender as string,
              dob: userProfile.birthday,
              membershipType: userProfile.membership.membership_type,
              membershipSubscription:
                userProfile.membership.subscription?.shortname,
              paymentFrequency:
                userProfile.membership.subscription?.plan.shortname.includes(
                  'yearly'
                )
                  ? 'Yearly'
                  : 'Monthly',
              // This type is outdated
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              created_at: userProfile.membership.created_at,
              canceled_at: userProfile.membership.canceled_at,
              true_expires_at: userProfile.membership.true_expires_at,
              true_membership_type: userProfile.membership.true_membership_type,
              has_gaia_brandpass: userProfile.membership.has_gaia_brandpass,
              trialGroup,
              gaia_brandpass_expiration:
                userProfile.membership.gaia_brandpass_expiration,
              has_trailforks_brandpass:
                userProfile.membership.has_trailforks_brandpass,
              trailforks_brandpass_expiration:
                userProfile.membership.trailforks_brandpass_expiration,
              signup_source: userProfile.signup_source,
              ...(userProfile.preferences && {
                preferences: userProfile.preferences
              }),
              ...(userProfile.sub_preferences && {
                sub_preferences: userProfile.sub_preferences
              })
            }
          });
        })
        // eslint-disable-next-line no-console
        .catch(() => console.error('Error fetching user data'));
    }
  }, [authService.userState.isAuthenticated]);

  return null;
};
