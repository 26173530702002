import { useEffect } from 'react';

import { usePriorityPlus } from '../Provider';

const useOverflowMenu = () => {
  const priorityPlusInstance = usePriorityPlus();

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const buttonElement = priorityPlusInstance.getNavElements()['toggle-btn'];
      const dropdownBoxElement =
        priorityPlusInstance.getNavElements()['overflow-nav'];
      if (
        !dropdownBoxElement.contains(event.target as HTMLElement) &&
        !buttonElement.contains(event.target as HTMLElement)
      ) {
        priorityPlusInstance.setOverflowNavOpen(false);
      }
    };

    const handlePressEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        priorityPlusInstance.setOverflowNavOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handlePressEsc);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handlePressEsc);
    };
  }, [priorityPlusInstance]);
};

export default useOverflowMenu;
