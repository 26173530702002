type SubNavLink = {
  href: string;
  text: string;
};

export const SUBNAV_LINKS: SubNavLink[] = [
  {
    text: 'Backpacking',
    href: '/category/Backpacking'
  },
  {
    text: 'Biking',
    href: '/category/Biking'
  },
  {
    text: 'Board Sports',
    href: '/category/Board Sports'
  },
  {
    text: 'Climbing',
    href: '/category/Climbing'
  },
  {
    text: 'Creating',
    href: '/category/Creating'
  },
  {
    text: 'Fitness',
    href: '/category/Fitness'
  },
  {
    text: 'Mapping',
    href: '/category/Mapping'
  },
  {
    text: 'Nutrition',
    href: '/category/Nutrition'
  },
  {
    text: 'Skiing',
    href: '/category/Skiing'
  },
  {
    text: 'Yoga',
    href: '/category/Yoga'
  }
];
