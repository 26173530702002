import useAnalytics from './useAnalytics';
import useAnimateMenu from './useAnimateMenu';
import useHighlighter from './useHighlighter';
import useOverflowMenu from './useOverflowMenu';
import { useHideNavigation } from './useHideNavigation';

const PriorityPlusHooks = () => {
  useHighlighter();
  useOverflowMenu();
  useAnimateMenu();
  useAnalytics();
  useHideNavigation();

  return null;
};

export default PriorityPlusHooks;
