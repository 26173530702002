import priorityPlus from 'priority-plus';

import { highlightCategoryLink } from './helpers/highlightCategoryLink';
import { PriorityInstance } from './types';

const DEFAULT_SVG_CLASS = 'stroke-white';
const HIGHLIGHT_SVG_CLASS = 'stroke-accent-secondary-active';

const DEFAULT_BUTTON_CLASS = {
  TEXT: 'text-white',
  OPACITY: 'opacity-80'
};
const HIGHLIGHT_BUTTON_CLASS = {
  TEXT: 'text-accent-secondary-active',
  OPACITY: 'opacity-100'
};

const DEFAULT_OVERFLOW_WRAPPER_CLASS = {
  VISIBILITY: '[visibility:hidden]',
  OPACITY: 'opacity-0'
};

const ACTIVE_OVERFLOW_WRAPPER_CLASS = {
  VISIBILITY: '[visibility:visible]',
  OPACITY: 'opacity-100'
};

const addHighlightStylesToButton = (buttonElement: HTMLElement) => {
  // Swap styles for highlight variant
  buttonElement.classList.replace(
    DEFAULT_BUTTON_CLASS.TEXT,
    HIGHLIGHT_BUTTON_CLASS.TEXT
  );
  buttonElement.classList.replace(
    DEFAULT_BUTTON_CLASS.OPACITY,
    HIGHLIGHT_BUTTON_CLASS.OPACITY
  );

  // Set SVG stroke to highlight color
  const SVGElement = buttonElement.lastElementChild;
  SVGElement.classList.replace(DEFAULT_SVG_CLASS, HIGHLIGHT_SVG_CLASS);
};
const removeHighlightStylesFromButton = (buttonElement: HTMLElement) => {
  // Swap styles to default
  buttonElement.classList.replace(
    HIGHLIGHT_BUTTON_CLASS.TEXT,
    DEFAULT_BUTTON_CLASS.TEXT
  );
  buttonElement.classList.replace(
    HIGHLIGHT_BUTTON_CLASS.OPACITY,
    DEFAULT_BUTTON_CLASS.OPACITY
  );

  // Change SVG stroke to default
  const SVGElement = buttonElement.lastElementChild;
  SVGElement.classList.replace(HIGHLIGHT_SVG_CLASS, DEFAULT_SVG_CLASS);
};

export const handleCategoryHighlight = (
  instance: ReturnType<typeof priorityPlus>,
  selectedCategory: string
) => {
  const elements = instance.getNavElements();
  const dropdownBoxElement = elements['overflow-nav'];
  const buttonElement = elements['toggle-btn'];

  highlightCategoryLink(instance, selectedCategory);

  // Check if selected category is hidden in the dropdownBox
  const hiddenItemNames = Array.from(dropdownBoxElement.childNodes).map(
    (child) => child.textContent
  );
  const isCategoryHidden = hiddenItemNames.includes(selectedCategory);

  return isCategoryHidden
    ? addHighlightStylesToButton(buttonElement)
    : removeHighlightStylesFromButton(buttonElement);
};

export const showOverflowMenu = (instance: PriorityInstance) => {
  const elements = instance.getNavElements();
  const dropdownBoxElement = elements['overflow-nav'];

  dropdownBoxElement.classList.replace(
    DEFAULT_OVERFLOW_WRAPPER_CLASS.VISIBILITY,
    ACTIVE_OVERFLOW_WRAPPER_CLASS.VISIBILITY
  );

  dropdownBoxElement.classList.replace(
    DEFAULT_OVERFLOW_WRAPPER_CLASS.OPACITY,
    ACTIVE_OVERFLOW_WRAPPER_CLASS.OPACITY
  );
};

export const hideOverflowMenu = (instance: PriorityInstance) => {
  const elements = instance.getNavElements();
  const dropdownBoxElement = elements['overflow-nav'];

  dropdownBoxElement.classList.replace(
    ACTIVE_OVERFLOW_WRAPPER_CLASS.VISIBILITY,
    DEFAULT_OVERFLOW_WRAPPER_CLASS.VISIBILITY
  );

  dropdownBoxElement.classList.replace(
    ACTIVE_OVERFLOW_WRAPPER_CLASS.OPACITY,
    DEFAULT_OVERFLOW_WRAPPER_CLASS.OPACITY
  );
};
