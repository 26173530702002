import HeaderLogo from 'components/01-atoms/HeaderLogo';
import dynamic from 'next/dynamic';
import { getCookie } from 'cookies-next';
import { RIVT_TOKEN_COOKIE_NAME } from 'lib/middleware/constants';
import { useUserProfile } from 'hooks/useIdentifyAnalytics/helpers';

const HeaderWC = dynamic(() => import('./HeaderWC'), { ssr: false });

const Header = () => {
  const rivtToken = getCookie(RIVT_TOKEN_COOKIE_NAME);
  const { data, isFetching } = useUserProfile(rivtToken);

  const renderContent = () =>
    !isFetching ? (
      <HeaderWC RIVTProfile={data}>
        <HeaderLogo />
      </HeaderWC>
    ) : null;

  return (
    <div className="sticky top-0 z-30 h-[var(--outside-header-height)] w-full bg-foreground-default">
      {renderContent()}
    </div>
  );
};

export default Header;
