import priorityPlus from 'priority-plus';

export const PP_CLASSNAMES: Parameters<typeof priorityPlus>[1]['classNames'] = {
  main: ['p-plus', 'gap-x-2', 'sm:gap-x-6', 'max-w-[1000px]'],
  'overflow-nav': [
    'p-plus__overflow',
    'pp-overflow-wrapper',
    '!block',
    'bg-ds-neutral-900',
    'min-w-[300px]',
    'py-2',
    'text-white',
    'rounded-lg',
    'shadow-lightmode-shadow-lg',
    'opacity-0',
    '[visibility:hidden]',
    'delay-100',
    'ease-linear'
  ],
  container: [
    'p-plus-container',
    'w-full',
    'flex',
    'justify-center',
    'z-20',
    'h-full',
    'items-center'
  ],
  'primary-nav': ['p-plus__primary', 'gap-x-2', 'sm:gap-x-6'],
  'toggle-btn': [
    'p-plus__toggle-btn',
    'pp-toggle-button',
    'font-plus-jakarta-sans',
    'font-semibold',
    'text-12',
    'sm:text-16',
    'flex',
    'items-center',
    'gap-2',
    'text-white',
    'hover:text-accent-secondary-hover',
    'focus-visible:outline-none',
    'focus-visible:ring-2',
    'focus-visible:ring-focus',
    'group'
  ]
};

export const MOUNT_ID = 'subnav-mount';
export const WRAPPER_ID = 'subnav-wrapper';
