import { gql } from '@apollo/client';

export const GET_COURSE_INTRODUCTION = gql`
  query CourseIntroduction(
    $courseName: String!
    $courseId: BigInt
    $preview: Boolean
    $thumbnailId: BigInt
    $relatedCoursesCount: Int
  ) {
    props: course(name: $courseName, id: $courseId, isPreview: $preview) {
      id
      courseName: name
      title
      brand {
        image
      }
      overview {
        description
        title
      }
      hero {
        subhead
        video
      }
      heroImage(thumbnailId: $thumbnailId)
      instructors {
        bio
        name
        profilePicture
      }
      coverage {
        title
        text
        imageAttachment {
          file
          altText
        }
      }
      curriculum(isPreview: $preview) {
        lessons {
          id
          name
          image
          title
          description
          instructors {
            name
          }
        }
      }
      mediaBlocks {
        title
        description
        image {
          file
        }
      }
      tableOfContents(isPreview: $preview) {
        title
        lessons {
          id
          name
          title
          completed
        }
      }
      relatedCourses(relatedCoursesCount: $relatedCoursesCount) {
        id
        name
        heroImage
        title
        excerpt
        instructors {
          name
        }
      }
      seo(isPreview: $preview) {
        title
        ogTitle
        description
        ogDescription
        publishedTime
        modifiedTime
        ogImageWidth
        ogImage
        ogImageHeight
      }
    }
  }
`;
