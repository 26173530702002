import type { FC } from 'react';

import type {
  TrackingTriggerProps,
  TrackingProviderProps,
  TrackingTriggerPayload
} from './event-tracker/types';
import { TrackingProvider, TrackingTrigger } from './event-tracker';

export { AnalyticsDefaultTrigger } from './analytics-provider.core';
export { TrackingContext as AnalyticsContext } from './event-tracker';
export type { TrackingTriggerPayload };

/* Our default payload */
export const AnalyticsDefaultPayload = {
  eventId: '',
  props: {
    domain: 'ouside-inc'
  }
};

/* Our default options */
export const AnalyticsDefaultOptions = {
  activeDestinations: [],
  writeKey: '', // Necessary
  analyticsJsUrl: '' // Necessary
};

export const AnalyticsTrigger: FC<TrackingTriggerProps> = ({
  event,
  payload,
  options,
  children
}) => (
  <TrackingTrigger {...{ event, payload, options }}>{children}</TrackingTrigger>
);

export const AnalyticsProvider: FC<TrackingProviderProps> = ({
  payload,
  options,
  trigger,
  children
}) => (
  <TrackingProvider payload={payload} options={options} trigger={trigger}>
    {children}
  </TrackingProvider>
);
