import { useEffect } from 'react';

import { usePriorityPlus } from '../Provider';
import { showOverflowMenu, hideOverflowMenu } from '../helpers';
import { PriorityPlusEventType } from '../types';

const SHOW_OVERFLOW_EVENT = 'showOverflow' as PriorityPlusEventType;
const HIDE_OVERFLOW_EVENT = 'hideOverflow' as PriorityPlusEventType;

const useAnimateMenu = () => {
  const priorityPlusInstance = usePriorityPlus();

  const showOverflowMenuHandler = () => showOverflowMenu(priorityPlusInstance);
  const hideOverflowMenuHandler = () => hideOverflowMenu(priorityPlusInstance);

  useEffect(() => {
    priorityPlusInstance.on(SHOW_OVERFLOW_EVENT, showOverflowMenuHandler);

    priorityPlusInstance.on(HIDE_OVERFLOW_EVENT, hideOverflowMenuHandler);

    return () => {
      priorityPlusInstance.off(SHOW_OVERFLOW_EVENT, showOverflowMenuHandler);

      priorityPlusInstance.off(HIDE_OVERFLOW_EVENT, hideOverflowMenuHandler);
    };
  }, [priorityPlusInstance]);
};

export default useAnimateMenu;
