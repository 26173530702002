import { useEffect } from 'react';

import { WRAPPER_ID } from '../consts';
import { PriorityInstance } from '../types';
import { usePriorityPlus } from '../Provider';

const SCROLL_THRESHOLD = 200;
const SHOW_NAV_STYLE = 'translate-y-0';
const HIDE_NAV_STYLE = '-translate-y-35';

const onNavHide = (target: HTMLElement, priorityInstance: PriorityInstance) => {
  target.classList.remove(SHOW_NAV_STYLE);
  target.classList.add(HIDE_NAV_STYLE);
  priorityInstance.setOverflowNavOpen(false);
};

const onNavShow = (target: HTMLElement) => {
  target.classList.add(SHOW_NAV_STYLE);
  target.classList.remove(HIDE_NAV_STYLE);
};

const handleSubnavScrollFactory = (
  onHide: VoidFunction,
  onShow: VoidFunction
) => {
  let previousPosition = window.scrollY;

  return () => {
    const currentPosition = window.scrollY;
    const shouldHideNav =
      currentPosition > SCROLL_THRESHOLD && currentPosition > previousPosition;

    if (shouldHideNav) {
      onHide();
    } else {
      onShow();
    }
    previousPosition = currentPosition;
  };
};

export const useHideNavigation = () => {
  const priorityInstance = usePriorityPlus();

  useEffect(() => {
    const target = document.getElementById(WRAPPER_ID);
    const onHide = () => onNavHide(target, priorityInstance);
    const onShow = () => onNavShow(target);

    const onScroll = handleSubnavScrollFactory(onHide, onShow);

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [priorityInstance]);
};
