type Props = {
  category: string;
};

const CategoryHeader = ({ category }: Props) => (
  <div className="mb-4 flex h-[64px] w-full items-center justify-center bg-foreground-default pb-2">
    <h1 className="font-plus-jakarta-sans text-[35px] font-bold text-white">
      {category}
    </h1>
  </div>
);

export default CategoryHeader;
