import { useEffect } from 'react';

import { usePriorityPlus } from '../Provider';
import { useSelectedCategory } from '../useSelectedCategory';
import { handleCategoryHighlight } from '../helpers';
import { PriorityPlusEventType } from '../types';

const ITEMS_CHANGED_EVENT = 'itemsChanged' as PriorityPlusEventType;

const useHighlighter = () => {
  const priorityPlusInstance = usePriorityPlus();
  const { selectedCategory } = useSelectedCategory();

  // Re-register highlight handlers for different selected category
  useEffect(() => {
    const highlightHandler = () => {
      // Handle case in which resizing might lead the selected category to leave/enter dropdown
      handleCategoryHighlight(priorityPlusInstance, selectedCategory);
    };

    // Initial call to check if we're already on category page
    highlightHandler();

    priorityPlusInstance.on(ITEMS_CHANGED_EVENT, highlightHandler);

    return () => {
      priorityPlusInstance.off(ITEMS_CHANGED_EVENT, highlightHandler);
    };
  }, [selectedCategory, priorityPlusInstance]);
};

export default useHighlighter;
