/* eslint-disable no-underscore-dangle */
import type { FC } from 'react';

import { TrackingContext } from './context/tracking-context';
import deepMerge from './deep-merge';
import { TrackingProviderProps, Trigger, IContext } from './types';

/**
 * A React context provider that allows nesting to generate new context that
 * builds on parent context. This component allows applications to build the
 * payload and options for events declaratively and through nesting.
 */

const TrackingProvider: FC<TrackingProviderProps> = ({
  eventPayload,
  eventOptions,
  payload,
  options,
  trigger,
  overwrite = false,
  children
}) => {
  const _trigger: Trigger = (event?: string, _payload = {}, _options = {}) => {
    // eslint-disable-next-line no-underscore-dangle
    const data = _trackingContext._data;
    const name = event || data?.event;
    const _eventPayload = data?.eventPayload
      ? ((name && data?.eventPayload[name]) as Record<string, unknown>)
      : {};
    const _eventOptions = data?.eventOptions
      ? ((name && data?.eventOptions[name]) as Record<string, unknown>)
      : {};

    if (!name) {
      throw new TypeError('event is a required parameter');
    }

    return data?.trigger?.(
      name,
      deepMerge(data?.payload, _eventPayload, _payload),
      deepMerge(data?.options, _eventOptions, _options)
    );
  };

  const _trackingContext: IContext = {
    _data: {
      eventPayload,
      eventOptions,
      payload,
      options,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      trigger: trigger || (() => {})
    },
    hasProvider: true,
    trigger: _trigger
  };

  const mergeContextData = (
    data: Partial<TrackingProviderProps> = {
      eventPayload: {},
      eventOptions: {}
    }
  ): TrackingProviderProps => {
    let newData: Partial<TrackingProviderProps>;
    if (overwrite) {
      newData = {
        eventPayload: eventPayload || data.eventPayload,
        eventOptions: eventOptions || data.eventOptions,
        payload: payload || data.payload,
        options: options || data.options
      };
    } else {
      // Not an overwrite so merge the properties and context objects
      newData = {
        eventPayload: deepMerge(data.eventPayload, eventPayload),
        eventOptions: deepMerge(data.eventOptions, eventOptions),
        payload: deepMerge(data.payload, payload),
        options: deepMerge(data.options, options)
      };
    }

    return newData as TrackingProviderProps;
  };

  const renderProvider = ({ _data }: IContext = {}) => {
    _trackingContext._data = mergeContextData(_data);
    // The specified trigger property takes precedence or fallback to one
    // inherited from parent context (for nesting) or default to empty function.
    _trackingContext._data.trigger =
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      trigger || _data?.trigger || (() => {});

    return (
      <TrackingContext.Provider value={_trackingContext}>
        {children}
      </TrackingContext.Provider>
    );
  };

  return (
    // Consume the context and then generate a new context provider
    // as a merge of specified properties and existing context.
    <TrackingContext.Consumer>{renderProvider}</TrackingContext.Consumer>
  );
};

export { TrackingProvider };
