import { PriorityInstance } from '../types';
import { ChevronSVGData } from '../ChevronSVGData';

const CHEVRON_ICON_DEFAULT_CLASS =
  'stroke-white group-hover:stroke-accent-secondary-hover';
export const addSVGIcon = (instance: PriorityInstance) => {
  const buttonElement = instance.getNavElements()['toggle-btn'];

  buttonElement.insertAdjacentHTML('beforeend', ChevronSVGData);

  // SVG is the last element since it's inserted at "beforend" position
  const SVGElement = buttonElement.lastElementChild;
  // Use setAttribute because new DOM node doesn't have class attribute.
  SVGElement.setAttribute('class', CHEVRON_ICON_DEFAULT_CLASS);
};
