import { gql } from '@apollo/client';

export const GET_CATEGORY_DATA = gql`
  query GetCategoryPage($category: String!) {
    props: learnCategory(category: $category) {
      courses {
        id
        name
        title
        instructors {
          name
        }
        excerpt
        heroImage
      }
      standaloneLessons {
        id
        name
        title
        isStandalone
        instructors {
          name
        }
        heroImage: image
        overview {
          description
        }
      }
      hero {
        buttonLink
        buttonText
        subhead
      }
      name
      seo {
        title
        ogTitle
        description
        ogDescription
        publishedTime
        modifiedTime
        ogImage
        ogImageWidth
        ogImageHeight
      }
    }
  }
`;

export const GET_CATEGORIES = gql`
  query getLearnCategories {
    categories: learnCategories {
      id
      name
    }
  }
`;
