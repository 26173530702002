import { PriorityInstance } from '../types';

export const getNavItemElements = (instance: PriorityInstance): Element[] => {
  const elements = instance.getNavElements();
  const primaryWrapper = elements['primary-nav'];
  const overflowWrapper = elements['overflow-nav'];

  const primaryNavItems = Array.from(primaryWrapper.children);
  const overflowNavItems = Array.from(overflowWrapper.children);
  const navItems = [...primaryNavItems, ...overflowNavItems];
  return navItems;
};
