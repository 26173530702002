import clsx from 'clsx';

import CategoryHeader from './CategoryHeader';
import { useSelectedCategory } from './useSelectedCategory';
import { MOUNT_ID, WRAPPER_ID } from './consts';
import PriorityPlusProvider from './Provider';
import PriorityPlusHooks from './hooks';

const SubNavigation = () => {
  const { isOnCategoryPage, selectedCategory } = useSelectedCategory();

  /**
     The priority-plus library is quite opinionated. Some wrapper styles need to be added
     in an instance factory. The JSX structure of the <nav> element is also not arbitrary - we can
     modify the <li> elements, but things like buttons or <ul> are handled by the library itself.
     Styling <li> will style links in the navbar as well as the links in the dropdown menu, careful!
     I operate on DOM in the useEffect because the library won't let me style or edit some
     components in an elegant way. With all that said, it still streamlines work a lot.
     */

  return (
    <div
      id={WRAPPER_ID}
      className={clsx(
        'sticky top-15 z-20 flex w-full flex-col transition-transform duration-200 ease-in-out md:top-17'
      )}
    >
      <nav
        id={MOUNT_ID}
        className="mx-auto flex h-13 w-full items-center bg-foreground-default font-plus-jakarta-sans text-white sm:h-19"
      >
        <PriorityPlusProvider>
          <PriorityPlusHooks />
        </PriorityPlusProvider>
      </nav>
      {isOnCategoryPage && <CategoryHeader category={selectedCategory} />}
    </div>
  );
};
export default SubNavigation;
