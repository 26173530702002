import {
  getActiveDestinations,
  hasConsentGroup,
  onConsentChanged
} from './helpers/cookieConsent';

/* Used to determine if promise is active. */
let isResolving = false;

/* Historical event storage. */
const historicalEvents = [];

/* Event storage proxy. */
export const eventsProxy = new Proxy([], {
  get(target, prop) {
    const val = target[prop];
    if (typeof val === 'function') {
      if (['push'].includes(prop)) {
        // eslint-disable-next-line func-names
        return function () {
          // eslint-disable-next-line prefer-rest-params
          const r = Array.prototype[prop].apply(target, arguments);
          // This starts our react event pipeline.
          processEvents();
          return r;
        };
      }
      return val.bind(target);
    }
    return val;
  }
});

/**
 * Function for loading analytics.js via MR.
 *
 * @param {string} writeKey
 * @param {string} analyticsJsUrl
 * @returns
 */
async function getAnalyticsJs({ writeKey, analyticsJsUrl, gtmId }) {
  if (hasAnalyticsJs() || isResolving) return true;
  isResolving = true;
  const isSecuritiEnabled = window.s__c;
  await Promise.all([
    new Promise((resolve, reject) => {
      // Get MetaRouter Analytics.js. (required)
      /*eslint-disable */
      var analytics = (window.analytics = window.analytics || []);
      if (!analytics.initialize)
        if (analytics.invoked)
          window.console &&
            console.error &&
            console.error('MetaRouter snippet included twice.');
        else {
          analytics.invoked = !0;
          analytics.methods = [
            'trackSubmit',
            'trackClick',
            'trackLink',
            'trackForm',
            'pageview',
            'identify',
            'reset',
            'group',
            'track',
            'ready',
            'alias',
            'page',
            'once',
            'off',
            'on'
          ];
          analytics.factory = function (t) {
            return function () {
              var e = Array.prototype.slice.call(arguments);
              e.unshift(t);
              analytics.push(e);
              return analytics;
            };
          };
          for (var t = 0; t < analytics.methods.length; t++) {
            var e = analytics.methods[t];
            analytics[e] = analytics.factory(e);
          }
          analytics.load = function (t) {
            var e = document.createElement('script');
            e.type = 'text/javascript';
            e.defer = !0;
            e.async = !0;
            e.src =
              ('https:' === document.location.protocol
                ? 'https://'
                : 'http://') + analyticsJsUrl;
            e.onload = function () {
              console.log('MetaRouter loaded');
              resolve(true);
            };
            e.onerror = function () {
              console.log('MetaRouter failed');
              reject(false);
            };
            var n = document.getElementsByTagName('script')[0];
            n.parentNode.insertBefore(e, n);
          };
          analytics.SNIPPET_VERSION = '3.1.0';
          analytics.load(
            writeKey,
            isSecuritiEnabled && { integrations: getActiveDestinations() }
          );
          if (isSecuritiEnabled) {
            window.addEventListener('message', (ev) => {
              if (ev.data.message === 'consent_given') {
                onConsentChanged();
              } else if (ev.data.message === 'consolidated_consent_given') {
                // Reload if consent has changed.
                // Use a proxy to get around a change in Essential that fires a false positive.
                const proxyConsent = JSON.parse(
                  JSON.stringify(window.s__c_g ? window.s__c_g : {})
                );
                if (!proxyConsent.Essential) proxyConsent.Essential = true;
                if (
                  window.s__c_i_g &&
                  JSON.stringify(proxyConsent) !==
                    JSON.stringify(ev.data.consentStatuses)
                ) {
                  location.reload();
                }
                onConsentChanged();
              }
            });
          }
        }
      /* eslint-enable */
    }),
    new Promise((resolve, reject) => {
      // Get GTM sdk. (optional)
      const shouldLaunchGTM = isSecuritiEnabled
        ? hasConsentGroup('performance') && hasConsentGroup('targeting')
        : true;
      if (!gtmId || !shouldLaunchGTM) {
        resolve(true);
      } else {
        /*eslint-disable */
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.defer = true;
          j.onload = function () {
            console.log('GTM loaded');
            resolve(true);
          };
          j.onerror = function () {
            console.log('GTM failed');
            reject(false);
          };
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', gtmId);
        /* eslint-enable */
      }
    })
  ]);
  isResolving = false;
  // eslint-disable-next-line no-console
  console.log('Analytics pipelines ready');
  processEvents();
  return true;
}

/**
 * Function for determining the status of analytics.js
 * in the browser.
 *
 * @returns {boolean}
 */
function hasAnalyticsJs() {
  return !!window.analytics;
}

/**
 * Funciton for calling our pipeline(s).
 *
 * @param {Object} Event
 * @returns {boolean}
 */
function callEvent(Event) {
  if (!hasAnalyticsJs() || !Event) return false;
  const {
    event,
    payload: { eventId, props }
  } = Event;

  try {
    // Push event into MR
    window.analytics[event].call(
      window.analytics,
      ...(eventId && [eventId]),
      props
    );
    // Push event into GTM
    if (window.dataLayer) {
      window.dataLayer.push({
        event,
        payload: {
          ...(eventId && { eventId }),
          ...(props && { props })
        }
      });
    }
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return false;
  }
}

/**
 * Function for processing our events.
 *
 * @returns {boolean}
 */
function processEvents() {
  if (!hasAnalyticsJs()) {
    const {
      options: { writeKey, analyticsJsUrl, gtmId }
    } = eventsProxy[0];
    getAnalyticsJs({ writeKey, analyticsJsUrl, gtmId });
    // Come back later when sdk(s) are loaded.
    return false;
  }
  while (eventsProxy.length) {
    const event = eventsProxy.shift();
    if (callEvent(event)) {
      historicalEvents.push(event);
    }
  }
  return true;
}

/**
 * Function for triggering our events.
 *
 * @param {string} event
 * @param {object} payload
 * @param {object} options
 */
export const AnalyticsDefaultTrigger = (event, payload, options) => {
  eventsProxy.push({ event, payload, options });
};
