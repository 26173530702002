const consentGroups = {
  necessary: 'Essential', // false if the others are true.
  performance: 'Analytics & Customization',
  functional: 'Performance & Functionality',
  targeting: 'Advertising',
  unclassified: 'Unclassified'
};

const metaRouterConsentGroups = {
  necessary: 'C0001', // Essential
  performance: 'C0002', // Analytics & Custimization
  functional: 'C0003', // Performance & Functionality
  targeting: 'C0004', // Advertising
  social: 'C0005' // Social Networking (Doesn't exist in Securiti but used in MR)
};

const destinations = [
  { name: 'Amplitude', groupNames: ['functional'] },
  { name: 'Google Analytics', groupNames: ['functional'] },
  { name: 'HubSpot', groupNames: ['functional'] },
  { name: 'Comscore', groupNames: ['functional'] },
  { name: 'Google AdWords', groupNames: ['targeting'] },
  { name: 'Bing Ads', groupNames: ['targeting'] }
];

function reverseObject(obj) {
  return Object.fromEntries(Object.entries(obj).map((a) => a.reverse()));
}

function getConsentString(securitiGroups) {
  const reversedGroups = reverseObject(consentGroups);

  let consentString = `groups=${Object.keys(securitiGroups).reduce(
    (acc, key) => {
      const mrKey = reversedGroups[key];
      if (metaRouterConsentGroups[mrKey]) {
        return `${acc}${acc ? ',' : ''}${metaRouterConsentGroups[mrKey]}:${
          securitiGroups[key] || mrKey === 'necessary' ? '1' : '0'
        }`;
      }
      return acc;
    },
    'C0001:1'
  )}`;

  // Add social if targeting.
  consentString += !consentString.includes('C0004:0') ? ',C0005:1' : ',C0005:0';

  return consentString;
}

export function hasConsentGroup(groupName) {
  const sKey = consentGroups[groupName];
  if (groupName === 'necessary') return true;
  return window.s__c_g ? window.s__c_g[sKey] : false;
}

export function getActiveDestinations() {
  const consentValues = Object.keys(consentGroups).map((group) =>
    hasConsentGroup(group)
  );
  if (consentValues.includes(false)) {
    return destinations.reduce(
      (ac, destination) => {
        // eslint-disable-next-line no-param-reassign
        ac[destination.name] = destination.groupNames.every((name) =>
          hasConsentGroup(name)
        );
        return ac;
      },
      { All: false }
    );
  }
  return { All: true };
}

export function onConsentChanged() {
  document.cookie = `OptOutOnRequest=${getConsentString(window.s__c_g)}`;
}
