/**
 * Merges objects without modifying inputs.
 * Arrays are concatinated.
 * Object are merged recursively.
 *
 * @param {...object} inputObjects - Objects to merge
 * @returns {object} Merged Object
 */
function deepMerge(
  ...inputObjects: (undefined | Record<string, unknown>)[]
): Record<string, unknown> | undefined {
  const isObject = (o: unknown) => o && typeof o === 'object';
  return inputObjects?.reduce((accumulated, current) => {
    if (!current) {
      return accumulated;
    }

    const workingObj = { ...accumulated };
    Object.keys(current).forEach((key) => {
      if (
        accumulated &&
        Array.isArray(accumulated[key]) &&
        Array.isArray(current[key])
      ) {
        workingObj[key] = [...(accumulated[key] as unknown[]), current[key]];
      } else if (
        accumulated &&
        isObject(accumulated[key]) &&
        isObject(current[key])
      ) {
        workingObj[key] = {
          ...deepMerge(accumulated[key] as Record<string, unknown>),
          ...(current[key] as Record<string, unknown>)
        };
      } else {
        workingObj[key] = current[key];
      }
    });
    return workingObj;
  }, {});
}

export default deepMerge;
