// TODO - https://linear.app/outside/issue/WEB-2189/export-constants-and-types-from-pocketoutdoormediaauth-hub-package-and
export const OUTSIDE_ACCESS_COOKIE_NAME = 'outside_access';
export const PIANO_COOKIE_NAME = '_pid';
export const RIVT_TOKEN_COOKIE_NAME = '_r_token';
export const COOKIE_CONSENT_COOKIE_MAX_AGE_SECONDS = 31536000; // 31536000 seconds === 1 year
export const COOKIE_CONSENT_COOKIE_NAME = 'OptOutOnRequest';
export const COOKIE_CONSENT_DEFAULT_VALUE =
  'groups=C0001:1,C0002:1,C0003:1,C0004:1,C0005:1';
export const COOKIE_TTL_DAYS = 30;
export const COOKIE_MAX_AGE_IN_SECONDS = COOKIE_TTL_DAYS * 86400;
export const BFF_LOGIN_PATH = '/login';
