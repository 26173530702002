import { useQuery } from '@apollo/client';
import {
  GET_CATEGORY_DATA,
  GET_COURSE_INTRODUCTION,
  GET_LESSON_DATA,
  GET_STANDALONE_LESSON_DATA
} from 'queries';
import { GET_LEARN_PAYWALL, GET_LEARN_CTA } from 'queries/componentQueries';

export const useCache = (variables) => {
  const {
    lessonName,
    courseName,
    preview,
    category,
    isStandaloneLesson,
    getCourseIntro
  } = variables || {};

  const { data: ctaData } = useQuery(GET_LEARN_CTA, {
    fetchPolicy: 'cache-only'
  });
  const { cta } = ctaData || {};
  const { data: paywallData } = useQuery(GET_LEARN_PAYWALL, {
    fetchPolicy: 'cache-only'
  });
  const { paywall } = paywallData || {};

  const { data: categoryData } = useQuery(GET_CATEGORY_DATA, {
    variables,
    skip: !category
  });

  const {
    name: categoryName,
    seo: categorySeo,
    courses,
    standaloneLessons
  } = categoryData?.props || {};
  const skipGetLessonData = isStandaloneLesson || (!preview && !lessonName);

  const { data: lessonData } = useQuery(GET_LESSON_DATA, {
    variables,
    skip: skipGetLessonData,
    errorPolicy: 'all'
  });

  const {
    id,
    description,
    title,
    image,
    video,
    seo,
    courseId,
    content,
    completed,
    freeContent,
    resources
  } = lessonData?.lesson || {};

  const { tableOfContents: lessonToc } = lessonData?.course || {};

  const { data: standaloneLessonData } = useQuery(GET_STANDALONE_LESSON_DATA, {
    variables,
    skip: !isStandaloneLesson || (!preview && !lessonName),
    errorPolicy: 'all'
  });

  const { lesson: standaloneLesson } = standaloneLessonData || {};
  const skipGetCourseIntro = !preview && !getCourseIntro;

  const { data: courseData } = useQuery(GET_COURSE_INTRODUCTION, {
    variables: { ...variables, relatedCoursesCount: 3 },
    skip: skipGetCourseIntro,
    errorPolicy: 'all'
  });

  const {
    tableOfContents,
    relatedCourses,
    brand,
    coverage,
    curriculum,
    hero,
    heroImage,
    instructors,
    mediaBlocks,
    name,
    overview,
    seo: courseSeo,
    title: courseTitle
  } = courseData?.props || {};

  // TODO: find a better way of organizing this hook
  return {
    categoryName,
    categorySeo,
    courses,
    standaloneLessons,
    cta,
    paywall,
    id,
    description,
    title,
    image,
    video,
    seo,
    courseId,
    courseName,
    lessonToc,
    content,
    freeContent,
    resources,
    tableOfContents,
    relatedCourses,
    completed,
    brand,
    coverage,
    curriculum,
    hero,
    heroImage,
    instructors,
    mediaBlocks,
    name,
    overview,
    courseSeo,
    courseTitle,
    standaloneLesson
  };
};
