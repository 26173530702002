import { useRouter } from 'next/router';

import { SUBNAV_LINKS } from './links';

export const useSelectedCategory = () => {
  const { query } = useRouter();
  const category = query.category as string;

  // Valid category page
  const isOnCategoryPage = SUBNAV_LINKS.map(({ text }) => text).includes(
    category
  );

  return { selectedCategory: category, isOnCategoryPage };
};
