import { gql } from '@apollo/client';

export const GET_LESSON_DATA = gql`
  query Lesson(
    $lessonName: String!
    $lessonId: BigInt
    $courseName: String!
    $courseId: BigInt
    $preview: Boolean
    $thumbnailId: BigInt
    $relatedCoursesCount: Int
  ) {
    lesson(name: $lessonName, id: $lessonId, isPreview: $preview) {
      id
      name
      title
      content
      completed
      resources {
        title
        target
        url
      }
      freeContent: previewContent
      image(thumbnailId: $thumbnailId)
      video
      isStandalone
      seo(isPreview: $preview) {
        title
        ogTitle
        description
        ogDescription
        publishedTime
        modifiedTime
        ogImage
        ogImageWidth
        ogImageHeight
      }
    }
    course(name: $courseName, id: $courseId, isPreview: $preview) {
      id
      tableOfContents(isPreview: $preview) {
        title
        lessons {
          id
          name
          title
          completed
        }
      }
      relatedCourses(relatedCoursesCount: $relatedCoursesCount) {
        id
        name
        heroImage
        title
        excerpt
        instructors {
          name
        }
      }
    }
  }
`;
export const GET_LESSON_COMPLETION_DATA = gql`
  query Lesson($lessonName: String!) {
    props: lesson(name: $lessonName) {
      id
      completed
    }
  }
`;

export const GET_TABLE_OF_CONTENTS = gql`
  query Course(
    $courseName: String!
    $courseId: BigInt
    $preview: Boolean
    $relatedCoursesCount: Int
  ) {
    props: course(name: $courseName, id: $courseId, isPreview: $preview) {
      courseId: id
      courseTitle: title
      courseSeo: seo(isPreview: $preview) {
        description
      }
      courseOverview: overview {
        description
      }
      relatedCourses(relatedCoursesCount: $relatedCoursesCount) {
        id
        name
        heroImage
        title
        excerpt
        instructors {
          name
        }
      }
      tableOfContents(isPreview: $preview) {
        title
        lessons {
          id
          name
          title
          completed
        }
      }
    }
  }
`;

export const GET_STANDALONE_LESSON_DATA = gql`
  query StandaloneLesson(
    $lessonName: String!
    $preview: Boolean
    $thumbnailId: BigInt
  ) {
    lesson(name: $lessonName, isPreview: $preview) {
      id
      name
      title
      description
      content
      freeContent: previewContent
      resources {
        title
        target
        url
      }
      instructors {
        bio
        name
        profilePicture
      }
      image(thumbnailId: $thumbnailId)
      video
      isStandalone
      overview {
        title
        description
      }
      seo(isPreview: $preview) {
        title
        ogTitle
        description
        ogDescription
        publishedTime
        modifiedTime
        ogImage
        ogImageWidth
        ogImageHeight
      }
    }
  }
`;

export const SET_LESSON_COMPLETION = gql`
  mutation SaveLessonCompletion($data: LessonCompletionInput!) {
    saveLessonCompletion(data: $data) {
      successful
      lesson {
        id
        completed
      }
    }
  }
`;
