import { useEffect } from 'react';
import { useAnalyticsContext } from 'components/04-templates/AnalyticsProvider';

import { usePriorityPlus } from '../Provider';

const useAnalytics = () => {
  const priorityPlusInstance = usePriorityPlus();
  const { elementClicked } = useAnalyticsContext();

  useEffect(() => {
    const handleAnalytics = (event: Event) => {
      const buttonLinkName = (event.target as HTMLElement).dataset.buttonLink;
      const isButtonLink = Boolean(buttonLinkName);

      if (!isButtonLink) return;

      elementClicked({
        name: `sub-navigation-${buttonLinkName}`
      });
    };

    const parent = priorityPlusInstance.getNavElements().main;
    parent.addEventListener('click', handleAnalytics);

    return () => parent.removeEventListener('click', handleAnalytics);
  }, [elementClicked, priorityPlusInstance]);
};

export default useAnalytics;
