/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef } from 'react';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from 'helpers/hooks';
import { useAuthService } from '@pocketoutdoormedia/auth-state-service';

export const LearnFederationProvider: FC = ({ children }) => {
  const { userState } = useAuthService();

  const store = useApollo();
  const isFirst = useRef(true);

  useEffect(() => {
    // don't reset the store when the useEffect runs the first time by default
    if (isFirst.current) {
      isFirst.current = false;
    } else {
      // if the accessToken has changed (login or logout), reset the store
      const resetApolloStore = async () => {
        await store.resetStore();
      };
      resetApolloStore();
    }
  }, [userState]);

  return <ApolloProvider client={store}>{children}</ApolloProvider>;
};

export default LearnFederationProvider;
