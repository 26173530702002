import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import { useRouter } from 'next/router';

import { PriorityInstance } from '../types';
import { priorityPlusFactory } from '../Factory';

type Props = {
  children: ReactNode;
};

const PriorityPlusContext = createContext<PriorityInstance | null>(null);

const PriorityPlusProvider = ({ children }: Props) => {
  const [priorityPlusInstance, setPriorityPlusInstance] =
    useState<PriorityInstance | null>(null);
  const router = useRouter();

  // Initialize priority-plus, store in state
  useEffect(() => {
    const instance = priorityPlusFactory(router);
    setPriorityPlusInstance(instance);
  }, []);

  return priorityPlusInstance ? (
    <PriorityPlusContext.Provider value={priorityPlusInstance}>
      {children}
    </PriorityPlusContext.Provider>
  ) : null;
};

export const usePriorityPlus = (): PriorityInstance => {
  const ctx = useContext(PriorityPlusContext);
  if (!ctx) throw new Error('Priority Plus Context out of bounds');

  return ctx;
};
export default PriorityPlusProvider;
