import { NextRouter } from 'next/router';
import kebabCase from 'lodash/kebabCase';
import priorityPlus from 'priority-plus';

import { PriorityInstance } from '../types';
import { MOUNT_ID, PP_CLASSNAMES } from '../consts';
import { SUBNAV_LINKS } from '../links';
import { addSVGIcon } from '../helpers/addSVGIcon';
import { getNavItemElements } from '../helpers/getNavItemElements';

const LIST_ITEM_STYLES =
  'list-none font-plus-jakarta-sans text-12 rounded font-semibold text-inherit sm:text-16 hover:cursor-pointer hover:text-accent-secondary-hover active:text-accent-secondary-active';
const BUTTON_STYLES =
  'block px-1.5 py-0.5 md:px-3 md:py-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-focus';

export const priorityPlusFactory = (router: NextRouter): PriorityInstance => {
  const rootNode = createPriorityPlusRootNode();
  mountPriorityRootNode(rootNode);

  const priorityInstance = createPriorityInstance(rootNode);

  addSVGIcon(priorityInstance);
  // PriorityPlus doesn't copy event listeners
  addClickHandlersToButtons(priorityInstance, router);

  return priorityInstance;
};

const createNavigationListElements = (): Element[] =>
  SUBNAV_LINKS.map((link) => {
    const listElement = document.createElement('li');
    listElement.className = LIST_ITEM_STYLES;
    const buttonLink = document.createElement('button');
    buttonLink.setAttribute('data-button-link', kebabCase(link.text));
    buttonLink.className = BUTTON_STYLES;
    buttonLink.textContent = link.text;
    listElement.appendChild(buttonLink);

    return listElement;
  });

const createPriorityPlusRootNode = (): HTMLElement => {
  const priorityList = document.createElement('ul');
  priorityList.className = 'js-p-target';

  const navigationElements = createNavigationListElements();
  priorityList.append(...navigationElements);

  return priorityList;
};

const mountPriorityRootNode = (rootNode: HTMLElement) => {
  const mount = document.getElementById(MOUNT_ID);
  if (!mount) throw new Error('Missing PriorityPlus mount point');

  mount.appendChild(rootNode);
};

const addClickHandlersToButtons = (
  instance: PriorityInstance,
  router: NextRouter
) => {
  const navItems = getNavItemElements(instance);
  navItems.forEach((navItem) => {
    const button = navItem.firstChild;
    const categoryLink = `/category/${button.textContent}`;
    // Prefetch page data as if using next/link
    router.prefetch(categoryLink);
    button.addEventListener('click', () => {
      instance.setOverflowNavOpen(false);
      router.push(`/category/${button.textContent}`);
    });
  });
};

const createPriorityInstance = (rootNode: HTMLElement): PriorityInstance =>
  priorityPlus(rootNode, {
    classNames: PP_CLASSNAMES
  });
