import priorityPlus from 'priority-plus';

import { getNavItemElements } from './getNavItemElements';

const DEFAULT_TEXT_CLASS = 'text-inherit';
const DEFAULT_OPACITY_CLASS = 'opacity-80';
const HIGHLIGHT_TEXT_CLASS = 'text-accent-secondary-active';
const HIGHLIGHT_OPACITY_CLASS = 'opacity-100';
export const highlightCategoryLink = (
  instance: ReturnType<typeof priorityPlus>,
  category: string
) => {
  const navItems = getNavItemElements(instance);
  setDefaultStyles(navItems);

  const selectedItem = navItems.find(
    (navItem) => navItem.textContent === category
  );

  // If the user is on valid category page, highlight the element
  if (selectedItem) setHighlight(selectedItem);
};

/*
Make sure the DEFAULT styles exist, otherwise replace will not produce side effects!
 */
const setDefaultStyles = (items: Element[]) => {
  items.forEach((navItem) => {
    navItem.classList.replace(HIGHLIGHT_TEXT_CLASS, DEFAULT_TEXT_CLASS);
    navItem.classList.replace(HIGHLIGHT_OPACITY_CLASS, DEFAULT_OPACITY_CLASS);
  });
};

const setHighlight = (element: Element) => {
  element.classList.replace(DEFAULT_TEXT_CLASS, HIGHLIGHT_TEXT_CLASS);
  element.classList.replace(DEFAULT_OPACITY_CLASS, HIGHLIGHT_OPACITY_CLASS);
};
