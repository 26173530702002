import { useMemo } from 'react';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  from
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import fetch from 'cross-fetch';
// TODO - https://linear.app/outside/issue/WEB-2189/export-constants-and-types-from-pocketoutdoormediaauth-hub-package-and
import { Tokens } from 'lib/middleware/types';
import {
  OUTSIDE_ACCESS_COOKIE_NAME,
  PIANO_COOKIE_NAME,
  RIVT_TOKEN_COOKIE_NAME
} from 'lib/middleware/constants';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

const endpoints = {
  graphQL: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT
};

let lastRivtToken: string | null = null;

let initialApolloClient: ApolloClient<NormalizedCacheObject> | undefined;

export const watchInMemoryCache = new InMemoryCache({
  // typePolicies is not required to use Apollo with Next.js - only for doing pagination.
  typePolicies: {
    Query: {
      fields: {
        playlist: {
          keyArgs: false,
          merge(existing, incoming) {
            if (!incoming) return existing;
            if (!existing) return incoming;

            const { mediaItems, ...rest } = incoming;

            const result = rest;
            result.mediaItems = [...existing.mediaItems, ...mediaItems];

            return result;
          }
        },
        allSeries: {
          keyArgs: false,
          merge(existing, incoming) {
            if (!incoming) return existing;
            if (!existing) return incoming;

            const { mediaItems, ...rest } = incoming;

            const result = rest;
            result.mediaItems = [...existing.mediaItems, ...mediaItems];

            return result;
          }
        }
      }
    }
  }
});

export function createApolloClient(
  outsideAccessCookie: string,
  pianoCookie = '',
  rivtToken = ''
) {
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => ({
      headers: {
        ...headers,
        ...(outsideAccessCookie && {
          Cookie: `${OUTSIDE_ACCESS_COOKIE_NAME}=${outsideAccessCookie}; ${PIANO_COOKIE_NAME}=${pianoCookie}; ${RIVT_TOKEN_COOKIE_NAME}=${rivtToken}`
        })
      }
    }));

    return forward(operation);
  });

  const httpLink = new HttpLink({
    uri: endpoints.graphQL,
    credentials: 'same-origin',
    fetch
  });

  const retryLink = new RetryLink({
    attempts: {
      max: 3,
      retryIf: (error) => !!error
    }
  });

  return new ApolloClient({
    link: from([retryLink, authLink, httpLink]),
    cache: watchInMemoryCache,
    ssrMode: typeof window === 'undefined'
  });
}

// On the first call, it calls createApolloClient to initialize a new ApolloClient.
// On subsequent calls, it checks for an existing client and checks cookies and decides
// whether to create a new client.
export function initializeApollo(cookies?: Tokens) {
  let rivtToken = null;
  const terminateExistingClient = () => initialApolloClient.stop();
  const isServerSide = typeof window === 'undefined';

  if (typeof document !== 'undefined') {
    if (document?.cookie.split(';').length > 1) {
      // Parse the cookie string into a readable JSON
      const parsedCookies = document.cookie
        .split(';')
        .map((v) => v.split('='))
        .reduce((acc: { [key: string]: string }, v) => {
          acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(
            v[1].trim()
          );
          return acc;
        }, {});

      // eslint-disable-next-line no-underscore-dangle
      rivtToken = parsedCookies._r_token || null;
    }
  } else {
    rivtToken = cookies?.rivtToken || null;
  }

  const hasNewCookie = rivtToken !== lastRivtToken;

  const existingClientNewCookies = initialApolloClient && hasNewCookie;

  const createNewClient = !initialApolloClient || existingClientNewCookies;

  if (existingClientNewCookies) {
    terminateExistingClient();
  }

  const apolloClient = createNewClient
    ? createApolloClient(
        cookies?.outsideAccess,
        cookies?.pianoToken,
        cookies?.rivtToken
      )
    : initialApolloClient;

  // Save the last access token in memory
  lastRivtToken = rivtToken;

  if (isServerSide) return apolloClient;

  if (!initialApolloClient) initialApolloClient = apolloClient;

  return apolloClient;
}

export function useApollo(cookies?: Tokens) {
  const { rivtToken, outsideAccess, pianoToken } = cookies || {
    rivtToken: null,
    outsideAccess: null,
    pianoToken: null
  };

  const store = useMemo(
    () => initializeApollo({ rivtToken, outsideAccess, pianoToken }),
    [rivtToken, outsideAccess, pianoToken]
  );
  return store;
}
